<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Encabezado -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 105px; z-index: 1"
              color="success"
              small
              fab
              v-if="tienePermiso == 1"
              @click="agregarPaquete()"
              title="Agregar Paquete"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="info"
              small
              fab
              @click="generarLote()"
              title="Generar Lote"
            >
              <v-icon style="font-size: 20px;">fas fa-truck</v-icon>
            </v-btn>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                <span style="font-size: 24px;">Filtros</span>
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="4" class="py-1">
                    Tipo de Envio
                    <v-autocomplete
                      v-model="selectedTipoEnvio"
                      item-text="tipo_descripcion"
                      :items="tiposEnvios"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="py-1">
                    De Bodega
                    <v-autocomplete
                      v-model="selectedBodegaOrigen"
                      item-text="bodega_nombre"
                      :items="bodegas"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="selectedEstado"
                      item-text="estado_nombre"
                      :items="estados"
                      hide-details
                      clearable
                      outlined
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-1">
                    Fecha
                    <FechaPickerRango
                      v-model="fechaRango"
                      :limpiar.sync="limpiarFecha"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="d-flex justify-end py-1">
                    <BtnFiltroVue
                      :loading="load"
                      clase="mt-0 px-0"
                      @clear="limpiarFiltros()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Paquetes -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :items="paquetes"
          :loading="load"
          dense
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- Acciones -->
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="primary" @click="visualizar(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-eye</v-icon>
                </v-btn>
              </template>
              <span>Ver</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.movimiento_estado_id == 8">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="acepPaquete(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-check</v-icon>
                </v-btn>
              </template>
              <span>Aceptar</span>
            </v-tooltip>
            <v-tooltip bottom v-if="(item.movimiento_estado_id == 4) || (item.movimiento_estado_id == 6)">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="error" @click="generarEtiqueta(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-file-pdf</v-icon>
                </v-btn>
              </template>
              <span>Generar Etiqueta</span>
            </v-tooltip>
            <v-tooltip bottom v-if="((item.movimiento_estado_id == 4 || item.movimiento_estado_id == 6) && item.paquete_estado_id != 3)">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="indigo" @click="confirmarMovimiento(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-exchange-alt</v-icon>
                </v-btn>
              </template>
              <span>Generar Movimiento</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.paquete_tipo_id == 4">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="success" @click="descargarRemito(item)" v-on="on">
                  <v-icon class="mr-2" small>fas fa-file-download</v-icon>
                </v-btn>
              </template>
              <span>Descargar Remito</span>
            </v-tooltip>
          </template>
          <!-- Color para el estado del paquete -->
          <template v-slot:[`item.paquete_estado_nombre`]="{ item }">
            <v-chip small :color="getColorEstadoPaq(item.paquete_estado_id)" dark>{{ item.paquete_estado_nombre }}</v-chip>
          </template>
          <!-- Color para el estado del movimiento -->
          <template v-slot:[`item.movimiento_estado_nombre`]="{ item }">
            <v-chip small :color="getColorEstadoPaq(item.movimiento_estado_id)" dark>{{ item.movimiento_estado_nombre }}</v-chip>
          </template>
          <!-- Fecha -->
          <template v-slot:[`item.fecha`]="{item}">
            {{ moment(item.fecha).format('DD/MM/YYYY') }}
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Modal Ver Paquete -->
    <ModalVerPaquete 
      :ver="dialogVerPaquete"
      :datos="datosPaquete"
      :bodegasDestino="bodegasD"
      :bodegasOrigen="bodegas"
      @closeModalVer="cerrarModalVer"
    />
    <!-- Modal Agregar Paquete -->
    <ModalAddPaquete 
      :dialogActivo="dialogPaquete"
      :bodegasOrig="bodegas"
      :bodegasDest="bodegasD"
      :tiposPaquetes="tiposEnvios"
      :prov="provincias"
      @setearModalNewPaquete="setModalNuevoPaquete"
      @confimPackage="confirmarPaquete"
    />
    <!-- Modal Aceptar Paquete -->
    <ModalAcepPaquete 
      :dialogActivo="dialogAceptPaq"
      @aceptarPaq="confirmarAceptacion"
      @setearModalAceptPaq="setModalAceptPaq"
    />
    <!-- Modal Generar Movimientos Paquetes -->
    <ModalGenerarMovimientos 
      :dialogActivo="dialogMovim"
      :repartidores="repartidores"
      :bodegas="bodegasD"
      :id="selectedPaquete.paquete_id"
      @setearModalMovimientos="setModalMovimientos"
      @setPendientes="setearPendientes"
    />
    <!-- Modal Generar Lote -->
    <ModalGenerarLote 
      :dialogActivo="dialogLote"
      :bodegasOrig="bodegas"
      :bodegasDest="bodegasD"
      :repartidores="repartidores"
      @setearModalAddLote="setModalNuevoLote"
      @confirmLote="confirmarLote"
    />
    <ModalConfirmar
      :activoDialog="dialogConf"
      :titulo="tituloConfirmar"
      :texto="textoConfirmar"
      @action="confirmarAccion"
      @setearModalConfAccion="cerrarConfirmar"
    />
    <!-- <ModalConfirmarAccion 
      @ejecutarAccion="confirmarAccion"
    /> -->
  </div>
</template>

<script>
import FechaPickerRango from '../../components/util/FechaPickerRango.vue'
import BtnFiltroVue from '../../components/util/BtnFiltro.vue'
import SearchDataTableVue from '../../components/util/SearchDataTable.vue';
import ModalVerPaquete from '../../components/mayorista/paquetes/ModalVerPaquete.vue'
import ModalAcepPaquete from '../../components/mayorista/paquetes/ModalAcepPaquete.vue'
import ModalGenerarMovimientos from '../../components/mayorista/paquetes/ModalGenerarMovimientos.vue'
import ModalAddPaquete from '../../components/mayorista/paquetes/ModalAddPaquete.vue'
import ModalGenerarLote from '../../components/mayorista/paquetes/ModalGenerarLote.vue'
import ModalConfirmar from '../../components/util/ModalConfirmar.vue'
import { order_list_by } from '../../util/utils.js'
import moment from 'moment'
import download from 'downloadjs'

export default {
  name: 'IndexPaquetes',
  data() {
    return {
      moment: moment,
      panel: 0,
      tiposEnvios: [],
      selectedTipoEnvio: {},
      bodegas: [],
      bodegasD: [],
      selectedBodegaOrigen: {},
      estados: [],
      selectedEstado: {},
      fechaRango: [ null, null ],
      limpiarFecha: false,
      load: false,
      search: '',
      paquetes: [],
      headers: [
        { text: 'Id Paquete', align: 'center', value: 'paquete_id' },
        { text: 'Comp. Código', align: 'center', value: 'comp_codigo' },
        { text: 'Fecha', align: 'center', value: 'fecha' },
        { text: 'Destinatario', align: 'center', value: 'destinatario_nombre' },
        { text: 'Estado Paquete', align: 'center', value: 'paquete_estado_nombre' },
        { text: 'Estado Movimiento', align: 'center', value: 'movimiento_estado_nombre' },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      provincias: [],
      repartidores: [],
      datosPaquete: {
        cliente: '',
        documento: '',
        telefono: '',
        direccion: '',
        localidad: '',
        observacion: '',
        deBodega: {},
        aBodega: {},
        itemsPaquete: []
      },
      dialogVerPaquete: false,
      selectedPaquete: {},
      dialogMovim: false,
      tienePermiso: 0,
      dialogPaquete: false,
      dialogLote: false,
      opcion: 0,
      selectedNuevoPaquete: {},
      bodyNuevoLote: {},
      datosMovim: {},
      dialogAceptPaq: false,
      sortBy: 'paquete_id',
      sortDesc: true,
      dialogConf: false,
      tituloConfirmar: '',
      textoConfirmar: '',
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  components: {
    BtnFiltroVue,
    FechaPickerRango,
    SearchDataTableVue,
    ModalVerPaquete,
    ModalGenerarMovimientos,
    ModalAddPaquete,
    ModalGenerarLote,
    ModalConfirmar,
    ModalAcepPaquete
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      // obtengo todos los datos del Formulario
      this.$store.state.loading = true;
      let initPeticion = await this.$store.dispatch('paquetes/initFormPaquetes')
      this.$store.state.loading = false;
      if (initPeticion.resultado == 1){
        this.tiposEnvios  = initPeticion.tipos;                   order_list_by(this.tiposEnvios, 'tipo_descripcion');
        this.estados      = initPeticion.estados;                 order_list_by(this.estados,     'estado_nombre');
        this.bodegas      = initPeticion.bodegasO;                order_list_by(this.bodegas,     'bodega_nombre');
        this.bodegasD     = initPeticion.bodegasD;                order_list_by(this.bodegasD,    'bodega_nombre');
        this.provincias   = initPeticion.provincias;              order_list_by(this.provincias,  'provincia_nombre');
        this.repartidores = initPeticion.repartidores;            order_list_by(this.repartidores,'repartidor_nombre');
        this.tienePermiso = initPeticion.permiso[0].tienePermiso; 
      }else{
        this.tiposEnvios  = []
        this.estados      = []
        this.bodegas      = []
        this.bodegasD     = []
        this.provincias   = []
        this.repartidores = []
        return this.$store.dispatch('show_snackbar', { text: initPeticion.message, color: 'error' })
      }
    },
    getColorEstadoPaq(estado_id){
      if (estado_id == 2) return 'green'
      else if (estado_id == 3 ) return 'red'
      else return 'blue'
    },
    async buscar(){
      if(this.fechaRango[0] && this.fechaRango.length == 2){
        // asigno fechas
        let fechaDesde = moment(this.fechaRango[0]).format('DD/MM/YYYY')
        let fechaHasta = ''
        if (this.fechaRango[1].length > 0){
          fechaHasta = moment(this.fechaRango[1]).format('DD/MM/YYYY')
        }
        // tipo de envio
        let tipoEnvio = 0
        if (this.selectedTipoEnvio && Object.keys(this.selectedTipoEnvio).length > 0){
          tipoEnvio = this.selectedTipoEnvio.tipo_id
        }
        // bodega origen
        let deBodega = 0
        if (this.selectedBodegaOrigen && Object.keys(this.selectedBodegaOrigen).length > 0){
          deBodega = this.selectedBodegaOrigen.bodega_codigo
        }
        // estado de paquete
        let estadoPquete = []
        if (this.selectedEstado && Object.keys(this.selectedEstado).length > 0){
          estadoPquete.push(this.selectedEstado.estado_id)
        }
        this.paquetes = []
        let estadoMov = []
        let bodyBusqueda = {
          fechaDesde:   fechaDesde,
          fechaHasta:   fechaHasta,
          tipoEnvio:    tipoEnvio,
          deBodega:     deBodega,
          estadoPquete: estadoPquete,
          usuario:      this.$store.state.username,
          estadoMov:    estadoMov,
          repartidor_id:0
        }
        this.load = true
        let paquetesPeticion = await this.$store.dispatch('paquetes/getPaquetes', bodyBusqueda)
        this.load = false

        if(paquetesPeticion.resultado != 1){ //si hay error
          this.paquetes = [];
          return this.$store.dispatch('show_snackbar', { text: paquetesPeticion.msj, color: 'error' })
        }
        this.paquetes = paquetesPeticion.paquetes

        for(let i in this.paquetes){
          let [ dia, mes, anio ] = this.paquetes[i].fecha.split('/');
          this.paquetes[i].fecha = new Date(anio,mes-1,dia);
        }
      }
    },
    limpiarFiltros(){
      this.limpiarFecha = true
    },
    //Ver Paquete
    async visualizar(item){
      this.datosPaquete.cliente     = item.destinatario_nombre
      this.datosPaquete.documento   = item.destinatario_nro_doc
      this.datosPaquete.telefono    = item.destinatario_telefono
      this.datosPaquete.direccion   = item.destinatario_domicilio
      this.datosPaquete.localidad   = item.destinatario_localidad
      this.datosPaquete.observacion = item.observacion
      
      let bodegaOrig = this.bodegas.filter(element => element.bodega_codigo == item.bodega_origen) // obtengo bodega origen
      if (bodegaOrig.length > 0){
        this.datosPaquete.deBodega = bodegaOrig[0]
      }
      
      let bodegaDes = this.bodegasD.filter(element => element.bodega_codigo == item.bodega_destino) // obtengo la bodega destino
      if (bodegaDes.length > 0){
        this.datosPaquete.aBodega = bodegaDes[0]
      }
      this.datosPaquete.itemsPaquete = []

      this.$store.state.loading = true
      let detallesPeticion      = await this.$store.dispatch('paquetes/getDetallesPaquete', {paquete_id: item.paquete_id})
      this.$store.state.loading = false

      if(detallesPeticion.resultado != 1){
        return this.$store.dispatch('show_snackbar', { text: detallesPeticion.msj, color: 'error' })
      }
      this.datosPaquete.itemsPaquete = detallesPeticion.paquetes
      this.dialogVerPaquete = true
    },
    cerrarModalVer(value){
      this.dialogVerPaquete = value
    },
    //Accion del Modal Confirmar
    confirmarAccion(value){
      if (value == true){
        if (this.opcion == 1){
          this.grabarNuevoPaquete()
        }
        if (this.opcion == 2){
          this.grabarLote()
        }
        if(this.opcion == 3){
          this.aceptarPaquete();
        }
        if (this.opcion == 4){
          this.generarMovimiento({bodega: this.datosMovim.bodega, repartidor: this.datosMovim.repartidor})
        }
        this.dialogConf = false;
      }else{
        this.selectedNuevoPaquete = {}
        this.bodyNuevoLote        = {}
        this.datosMovim           = {}
      }
    },
    cerrarConfirmar(value){
      this.dialogConf = value;
    },
    //Nuevo
    agregarPaquete(){
      this.opcion = 1;
      this.tituloConfirmar  = 'Generar Nuevo Paquete';
      this.textoConfirmar   = '¿Confirma la grabación del nuevo paquete?';
      this.dialogPaquete    = true;       // abrir el modal
    },
    confirmarPaquete(value){
      this.selectedNuevoPaquete = value // guardo el body
      this.dialogConf           = true;
    },
    async grabarNuevoPaquete(){
      this.$store.state.loading = true;
      let paquetePeticion       = await this.$store.dispatch('paquetes/newPackage', this.selectedNuevoPaquete)
      this.$store.state.loading = false;
      if (paquetePeticion.resultado != 1){
        return this.$store.dispatch('show_snackbar', { text: paquetePeticion.msj, color: 'error' })
      }
      this.$store.dispatch('show_snackbar', { text: 'Nuevo paquete creado con Éxito.', color: 'success' })
      this.dialogPaquete = false
    },
    setModalNuevoPaquete(value){
      this.dialogPaquete = value
    },
    //Nuevo Lote
    generarLote(){
      this.opcion = 2
      this.tituloConfirmar = 'Generar Lote';
      this.textoConfirmar  = '¿Confirma la grabación del nuevo Lote?';
      this.dialogLote      = true //abrir el modal
    },
    confirmarLote(value){
      this.bodyNuevoLote = value //guardo el dato
      this.dialogConf    = true;
    },
    async grabarLote(){
      this.$store.state.loading = true;
      let lotePeticion          = await this.$store.dispatch('paquetes/generarLote', this.bodyNuevoLote)
      this.$store.state.loading = false;
      if (lotePeticion.resultado != 1){
        return this.$store.dispatch('show_snackbar', { text: lotePeticion.msj, color: 'error' })
      }
      this.$store.dispatch('show_snackbar', { text: 'Lote creado exitosamente.', color: 'success' })
      this.dialogLote = false //cierro el modal
    },
    setModalNuevoLote(value){
      this.dialogLote = value
    },
    //Aceptar Paquete
    async acepPaquete(item){
      this.opcion = 3
      this.selectedPaquete = item
      this.dialogAceptPaq  = true //abro el modal
    },
    confirmarAceptacion(){
      this.tituloConfirmar = 'Aceptar Paquete'
      this.textoConfirmar  = '¿Está seguro de aceptar el paquete? Confirmar acción.'
      this.dialogConf = true;
    },
    async aceptarPaquete(value){
      if (value.aceptar == true){
        this.$store.state.loading = true; 
        let aceptarPeticion = await this.$store.dispatch('paquetes/aceptarPaquete', {paquete_id: this.selectedPaquete.paquete_id, observacion: value.observacion})
        this.$store.state.loading = false;
        
        if (aceptarPeticion.resultado != 1){
          return this.$store.dispatch('show_snackbar', { text: aceptarPeticion.msj, color: 'error' })
        }
        this.$store.dispatch('show_snackbar', { text: 'El paquete fue aceptado exitosamente.', color: 'success' })
        setTimeout(() => [this.buscar()], 2000)
      }
    },
    setModalAceptPaq(value){
      this.dialogAceptPaq = value
    },
    //Nuevo Movimiento
    confirmarMovimiento(item){
      this.opcion = 4
      this.selectedPaquete = item //guardo la info del paq
      this.tituloConfirmar = 'Generar Movimiento de Paquete';
      this.dialogMovim     = true     //abro modal
    },
    setearPendientes(value){
      this.datosMovim = value
      if (value.movimientos.length > 0){
        this.textoConfirmar = 'Existen movimientos pendientes de aceptar para el paquete seleccionado. Si procede con la grabación todos los movimientos pendientes se cancelarán. ¿Desea continuar?';
      }else{
        this.textoConfirmar = 'Está generando un movimiento del paquete hacia la bodega ' + value.bodega.bodega_nombre + '. ¿Confirma esta acción?';
      }
      this.dialogConf = true;
    },
    async generarMovimiento(datos){
      let repartidor_id = 0 // armo el body para la consulta
      if (Object.keys(datos.repartidor).length > 0){
        repartidor_id = datos.repartidor.repartidor_codigo
      }
      let body = {
        bodega_origen:  this.selectedPaquete.bodega_actual_codigo,
        bodega_destino: datos.bodega.bodega_codigo,
        repartidor_id:  repartidor_id,
        paquetes:       this.selectedPaquete.paquete_id
      }
      
      this.$store.state.loading = true;
      let generarMovimPeticion = await this.$store.dispatch('paquetes/generarMovimiento', body) // envio a generar el movimiento
      this.$store.state.loading = false;
      
      if (generarMovimPeticion.resultado == 1){
        this.$store.dispatch('show_snackbar', { text: 'Movimiento creado con éxito.' , color: 'success' })
      }else{
        this.$store.dispatch('show_snackbar', { text: generarMovimPeticion.message, color: 'error' })
      }
      this.dialogMovim = false  //cierro modal
    },
    setModalMovimientos(value){
      this.dialogMovim = value
    },
    //Generar Etiqueta
    async generarEtiqueta(item){      
      let body = {// armo mi body para la API
        destinatario: item.destinatario_nombre,
        documento:    item.destinatario_nro_doc,
        domicilio:    item.destinatario_domicilio,
        localidad:    item.destinatario_localidad,
        telefono:     item.destinatario_telefono,
        paquete_id:   item.paquete_id
      }
      this.$store.state.loading = true;
      let etiquetaPedido        = await this.$store.dispatch('paquetes/generateLabel', body)
      this.$store.state.loading = false;
      
      if (etiquetaPedido.resultado == 1){
        download(etiquetaPedido.archivo, 'Etiqueta.pdf', 'application/pdf')
      }else{
        this.$store.dispatch('show_snackbar', { text: etiquetaPedido.msj, color: 'error' })
      }
    },
    //descargar el remito
    descargarRemito(item){
      window.open('http://200.45.250.4:8800/bb_web/public/mayorista/pdfremito/' + item.comp_codigo, '_blank')
    },
  },
  watch: {
    paquetes: function(){
      if (this.paquetes.length > 0){
        for (let id in this.paquetes){
          this.paquetes[id].fecha_log = moment(this.paquetes[id].fecha_log).format('DD/MM/YYYY')
        }
      }
    },
    dialogVerPaquete: function(){
      if (this.dialogVerPaquete == false){
        this.datosPaquete.cliente       = ''
        this.datosPaquete.documento     = ''
        this.datosPaquete.telefono      = ''
        this.datosPaquete.direccion     = ''
        this.datosPaquete.localidad     = ''
        this.datosPaquete.observacion   = ''
        this.datosPaquete.deBodega      = ''
        this.datosPaquete.aBodega       = ''
        this.datosPaquete.itemsPaquete  = []
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
}
</script>

<style>

</style>
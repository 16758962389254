<template>
  <div>
    <v-dialog v-model="activo" width="700">
      <v-card>
        <v-card-title class="" height="15%">
          <span class="">Paquete - Movimiento</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="activo = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4 pb-2">
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete 
                v-model="selectedBodega" 
                :items="bodegasMovim" 
                item-text="bodega_nombre" 
                label="Bodega destino" 
                dense
                hide-details
                return-object
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete 
                v-model="selectedRepartidor" 
                :items="repartidoresMovim"
                item-text="repartidor_nombre" 
                label="Repartidor" 
                dense
                hide-details
                return-object
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="$vuetify.theme.dark ?  'orange' : 'secondary' " @click="cerrarModalMovimiento">Cancelar</v-btn>
          <v-btn color="success" @click="validarMovimiento">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ModalGenerarMovimientos',
  props: {
    bodegas: {
      type: Array,
      default: []
    },
    repartidores: {
      type: Array,
      default: []
    },
    dialogActivo: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  components: {

  },
  data() {
    return {
      selectedBodega: {},
      selectedRepartidor: {},
      pantallaChica: this.$vuetify.breakpoint.xs,
    }
  },
  computed: {
    bodegasMovim: {
      get(){
        return this.bodegas
      }
    },
    repartidoresMovim: {
      get(){
        return this.repartidores
      }
    },
    activo: {
      get(){
        return this.dialogActivo
      },
      set(value){
        this.$emit('setearModalMovimientos', value)
      }
    }
  },
  methods: {
    async validarMovimiento(){
      if (Object.keys(this.selectedBodega).length == 0){
        // debe ingresar al menos la bodega
        return this.$store.dispatch('show_snackbar', { text: 'Debe seleccionar la Bodega de Destino.', color: 'info' })
      }
      // controlo los movimientos de paquetes
      this.$store.state.loading = true;
      // control de movimientos pendientes
      let pendientesPeticion = await this.$store.dispatch('paquetes/getMovimientosPendientes', {paquete_id: this.id})
      this.$store.state.loading = false;
      if (pendientesPeticion.resultado == 0){
        return this.$store.dispatch('show_snackbar', { text: pendientesPeticion.msj, color: 'error' })
      }
      // emito
      this.$emit('setPendientes', {movimientos: pendientesPeticion.movimientos, bodega: this.selectedBodega, repartidor: this.selectedRepartidor})
    },
    cerrarModalMovimiento(){
      this.selectedBodega = {}
      this.selectedRepartidor = {}
      if (this.activo == true){
        this.activo = false
      }
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false){
        this.cerrarModalMovimiento()
      }
    },
    '$vuetify.breakpoint.xs'(val){
      this.pantallaChica = val;
    },
  },
}
</script>

<style>

</style>